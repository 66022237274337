import classNames from "classnames";

export function LinkTiles({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<ul className={classNames("flex flex-col gap-4 lg:gap-6", className)}>
			{children}
		</ul>
	);
}
