import classNames from "classnames";
import React from "react";

export function Kicker({
	className,
	label,
}: {
	className?: string;
	label: React.ReactNode;
}) {
	return (
		<span
			className={classNames(
				"text-cycle-blue mt-1 block text-base font-bold uppercase",
				className,
			)}
		>
			{label}
		</span>
	);
}
