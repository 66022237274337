import { CycleHeader } from "@cycleplatform/ui/components/layout";
import classNames from "classnames";
import { useMatch } from "react-router-dom";

export const Header = () => {
	return (
		<header
			className="bg-cycle-black-accent fixed left-0 right-0 top-0 z-40 font-normal"
			id="root-nav"
		>
			<CycleHeader />
			<div className="flex justify-between border-b bg-white px-4 text-sm lg:hidden">
				<SubNavLink href="/platform" title="Platform" />
				<SubNavLink href="/internal" title="Internal" />
				<SubNavLink href="/scheduler" title="Scheduler" />
				<SubNavLink href="/ial" title="IAL" />
			</div>

			{/* Sub-navigation */}
			<div className="border-cycle-gray-light hidden w-full items-center justify-start border-b bg-white px-8 lg:flex">
				<nav className="flex items-center gap-8 py-2 text-sm">
					<SubNavLink href="/platform" title="Platform API Docs" />
					<SubNavLink href="/internal" title="Internal API Docs" />
					<SubNavLink href="/scheduler" title="Scheduler API Docs" />
					<SubNavLink href="/ial" title="IAL API Docs" />
				</nav>
			</div>
		</header>
	);
};

function SubNavLink({ title, href }: { title: string; href: string }) {
	const isMatch = !!useMatch(href);

	return (
		<div
			className={classNames(
				"hover:text-cycle-blue-accent border-transparent py-2 lg:border-b",
				isMatch && "text-cycle-blue !border-cycle-blue",
			)}
		>
			<a href={`/docs/api${href}`}>{title}</a>
		</div>
	);
}
