import { Outlet } from "react-router-dom";

import { Header } from "./Header";

export default function Layout() {
	return (
		<div className="flex h-screen flex-col overflow-hidden">
			<Header />
			<div className="flex-grow overflow-auto border-t pt-[100px]">
				<Outlet />
			</div>
		</div>
	);
}
