import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export function Dropdown({
	title,
	hoverContent,
	href,
}: {
	title: string;
	hoverContent?: React.ReactNode;
	href?: string;
}) {
	return (
		<div className="group flex h-[72px] items-center gap-2">
			<span className="text-cycle-white font-semibold leading-[4.5rem]">
				{href ? (
					<a
						className="hover:text-cycle-blue transition-all duration-200"
						href={href}
					>
						{title}
					</a>
				) : (
					<div className="hover:text-cycle-blue transition-all duration-200">
						{title}
					</div>
				)}
			</span>

			{hoverContent && (
				<div
					className={classNames(
						"bg-cycle-white absolute -left-[4rem] -right-[10rem] top-16 min-w-[20rem] rounded-lg shadow",
						"pointer-events-none opacity-0",
						"transition-opacity duration-200 ease-in-out group-hover:pointer-events-auto group-hover:opacity-100",
					)}
				>
					<div className="!flex p-4 px-6">{hoverContent}</div>
				</div>
			)}

			{hoverContent ? (
				<FontAwesomeIcon
					className="text-cycle-gray group-hover:text-cycle-white transition-all duration-200 group-hover:rotate-180"
					icon={faChevronDown}
				/>
			) : null}
		</div>
	);
}
