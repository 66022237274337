import "./styles.css";

import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Ial from "./routes/Ial";
import Internal from "./routes/Internal";
import Layout from "./routes/Layout";
import Platform from "./routes/Platform";
import Scheduler from "./routes/Scheduler";

ReactDOM.createRoot(document.getElementById("root")!).render(
	<BrowserRouter basename="/docs/api">
		<Routes>
			<Route index element={<Navigate replace to="platform" />} />

			{/* Actual app layout and nested routes */}
			<Route element={<Layout />} path="*">
				<Route element={<Platform />} path="platform/*" />
				<Route element={<Internal />} path="internal/*" />
				<Route element={<Scheduler />} path="scheduler/*" />
				<Route element={<Ial />} path="ial/*" />
			</Route>
		</Routes>
	</BrowserRouter>,
);
