import "@scalar/api-reference-react/style.css";

import { ApiReferenceReact } from "@scalar/api-reference-react";
import { useEffect } from "react";

import internal from "../../../../packages/cycle-openapi/public-spec/dist/internal.yml";

export default function Internal() {
	// FORCE light mode -> config is not 100% reliable
	useEffect(() => {
		const body = document.body;
		body.classList.remove("dark-mode");
		body.classList.add("light-mode");
	}, []);
	return (
		<ApiReferenceReact
			configuration={{
				hideDarkModeToggle: true,
				hideClientButton: true,
				darkMode: false,
				spec: {
					content: internal,
				},
				pathRouting: {
					basePath: "/docs/api/internal",
				},
			}}
		/>
	);
}
