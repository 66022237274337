import { faSlack } from "@fortawesome/free-brands-svg-icons";
import {
	faBars,
	faBinaryLock,
	faBlog,
	faBook,
	faBuildingMagnifyingGlass,
	faCalculator,
	faChartNetwork,
	faClouds,
	faCubes,
	faGamepadModern,
	faGear,
	faGears,
	faHouse,
	faLaptopMobile,
	faLayerGroup,
	faList,
	faMessages,
	faMicrochipAi,
	faMoneyBill1Wave,
	faScrewdriverWrench,
	faServer,
	faSparkles,
	faStars,
	faUsers,
} from "@fortawesome/pro-duotone-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { Kicker } from "../../typography/Kicker";
import { Dropdown } from "./Dropdown";
import { LinkTiles } from "./LinkTiles";
import { NavLink } from "./NavLink";

export function CycleHeader() {
	return (
		<>
			<div className="hidden lg:block">
				<CycleDesktopHeader />
			</div>

			<div className="lg:hidden">
				<CycleMobileHeader />
			</div>
		</>
	);
}
function CycleMobileHeader() {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenuModal = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div>
			<div className="flex h-16 w-full items-center justify-between">
				<div className="max-w-[15rem] p-4 lg:p-0">
					<a href="/">
						<img
							alt="Cycle Logo"
							className="max-h-[2.5rem]"
							src="https://static.cycle.io/icons/logo/logo-white.png"
						/>
					</a>
				</div>

				{/* Trigger Button Example - remove if already handled elsewhere */}
				<button
					className="h-[3rem] w-[3rem] p-2"
					onClick={toggleMenuModal}
				>
					<FontAwesomeIcon
						className="text-cycle-blue text-2xl"
						icon={faBars}
					/>
				</button>
			</div>

			{isOpen && (
				<div className="fixed inset-0 top-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
					<div className="h-full w-full p-4">
						<div className="bg-cycle-gray-accent h-full rounded-md p-4">
							<LinkTiles>
								<div className="width-full flex justify-center pt-4">
									<button
										className="border-cycle-blue hover:bg-cycle-blue h-12 w-12 items-center justify-center rounded-full border text-center"
										data-se="modal-nav-close"
										onClick={toggleMenuModal}
									>
										<FontAwesomeIcon
											className="!text-cycle-white pt-1 text-lg"
											icon={faTimes}
										/>
									</button>
								</div>

								<NavLink href="/" icon={faHouse} title="Home" />
								<NavLink
									href="/platform"
									icon={faLayerGroup}
									title="Platform"
								/>
								<NavLink
									href="/changelog/"
									icon={faList}
									title="Changelog"
								/>
								<NavLink
									href="/manifesto/"
									icon={faStars}
									title="Manifesto"
								/>
								<NavLink
									href="/pricing/"
									icon={faMoneyBill1Wave}
									title="Pricing"
								/>
								<NavLink
									href="/company/"
									icon={faUsers}
									title="Company"
								/>

								<NavLink
									href="/blog/"
									icon={faBlog}
									title="Blog"
								/>
							</LinkTiles>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

function CycleDesktopHeader() {
	return (
		<div className="flex h-[75px] w-full items-center justify-between px-8">
			{/* Logo */}
			<div className="max-w-[15rem] p-4 lg:p-0">
				<a href="/">
					<img
						alt="Cycle Logo"
						className="max-h-[2.5rem]"
						src="https://static.cycle.io/icons/logo/logo-white.png"
					/>
				</a>
			</div>

			<nav className="text-cycle-white relative flex gap-16">
				<Dropdown
					title="Platform"
					hoverContent={
						<>
							<div className="text-cycle-black w-[30rem] rounded-lg p-4 pt-6">
								<h4 className="mb-2 text-2xl font-bold">
									The Cycle Platform
								</h4>
								<p className="text-[1rem' mt-2">
									Cycle empowers development and devops teams
									with the tools and foundations needed to
									simplify, standardize, and scale even the
									most complex platforms.
								</p>
								<LinkTiles className="pl-4 pt-4">
									<NavLink
										href="http://cycle.io/platform/"
										icon={faLayerGroup}
										title="Platform Overview"
									/>

									<NavLink
										href="http://cycle.io/partners/technical"
										icon={faServer}
										title="Technical Partners"
									/>
								</LinkTiles>
							</div>
							<div className="w-[20rem] border-l pl-4">
								<LinkTiles>
									<Kicker
										className="!text-cycle-black block"
										label="Discover the Platform"
									/>

									<NavLink
										href="http://cycle.io/platform/container-orchestration/"
										icon={faCubes}
										title="Container Orchestration"
									/>

									<NavLink
										href="http://cycle.io/platform/infrastructure-management/"
										icon={faServer}
										title="Infrastructure Management"
									/>
									<NavLink
										href="/platform/networks/"
										icon={faChartNetwork}
										title="	Effortless Networking"
									/>

									<NavLink
										href="http://cycle.io/platform/security/"
										icon={faBinaryLock}
										title="Integrated Security"
									/>
								</LinkTiles>
							</div>
						</>
					}
				/>

				<Dropdown
					title="Solutions"
					hoverContent={
						<>
							<div className="w-[30rem]">
								<LinkTiles>
									<Kicker
										className="!text-cycle-black block pb-2"
										label="by Objective"
									/>
									<NavLink
										href="http://cycle.io/solutions/adopting-an-alternative-to-kubernetes/"
										icon={faGear}
										title="Adopting an Alternative to Kubernetes"
									/>

									<NavLink
										href="http://cycle.io/solutions/containers-on-bare-metal/"
										icon={faServer}
										title="Deploying Containers on Bare-Metal"
									/>

									<NavLink
										href="http://cycle.io/solutions/becoming-multi-cloud/"
										icon={faClouds}
										title="Becoming Multi-Cloud / Provider Agnostic"
									/>
								</LinkTiles>
							</div>
							<div className="w-[30rem]">
								<LinkTiles className="pl-16">
									<Kicker
										className="!text-cycle-black block pb-2"
										label="by use case"
									/>
									<NavLink
										href="http://cycle.io/solutions/platforms/"
										icon={faGears}
										title="Building Platforms (SaaS / PaaS)"
									/>

									<NavLink
										href="http://cycle.io/solutions/venture-studios/"
										icon={faLaptopMobile}
										title="Accelerating Venture Studios"
									/>

									<NavLink
										href="http://cycle.io/solutions/gaming/"
										icon={faGamepadModern}
										title="Hosting Multiplayer Games"
									/>

									<NavLink
										href="http://cycle.io/solutions/machine-learning-ai/"
										icon={faMicrochipAi}
										title="Scaling Machine Learning (AI)"
									/>
								</LinkTiles>
							</div>
						</>
					}
				/>
				<Dropdown href="http://cycle.io/pricing/" title="Pricing" />

				<Dropdown
					title="Resources"
					hoverContent={
						<>
							<div className="text-cycle-black !w-[30rem] rounded-lg">
								<LinkTiles>
									<Kicker
										className="!text-cycle-black block"
										label="Learn and Connect"
									/>

									<NavLink
										href="http://cycle.io/changelog"
										icon={faSparkles}
										title="Changelog"
									/>
									<NavLink
										href="https://cycle.io/docs"
										icon={faBook}
										title="Documentation"
									/>
									<NavLink
										href="http://cycle.io/community/"
										icon={faMessages}
										title="Community"
									/>
									<NavLink
										href="https://slack.cycle.io"
										icon={faSlack}
										title="Slack"
									/>

									<NavLink
										href="http://cycle.io/roi/"
										icon={faCalculator}
										title="Return on Investment (ROI) Calculator"
									/>

									<NavLink
										href="http://cycle.io/toolbox/global-dns-propogation-validator/"
										icon={faScrewdriverWrench}
										title="Free DevOps Tools"
									/>
								</LinkTiles>
							</div>
							<div className="border-l pl-4">
								<LinkTiles>
									<Kicker
										className="!text-cycle-black block"
										label="company"
									/>

									<NavLink
										href="http://cycle.io/manifesto/"
										icon={faStars}
										title="The Cycle Manifesto"
									/>

									<NavLink
										href="http://cycle.io/blog/"
										icon={faBlog}
										title="The Cycle Blog"
									/>

									<NavLink
										href="http://cycle.io/company/"
										icon={faBuildingMagnifyingGlass}
										title="About Us"
									/>
								</LinkTiles>
							</div>
						</>
					}
				/>
			</nav>

			{/* Login */}
			<nav
				className="text-cycle-white hidden max-w-[15rem] items-center justify-end gap-4 lg:flex"
				id="login"
			>
				<a
					className="text-cycle-white hover:text-cycle-blue font-semibold"
					href="https://portal.cycle.io"
					target="_blank"
				>
					Login
				</a>
				<button
					className="border-cycle-blue bg-cycle-blue hover:text-cycle-black text-cycle-white m-2 inline-block self-center rounded-lg border px-3 py-2 font-semibold transition-colors duration-300 ease-in-out"
					onClick={() =>
						window.open(
							"https://signup.cycle.io",
							"_blank",
							"noopener,noreferrer",
						)
					}
				>
					Get Started
				</button>
			</nav>

			{/* Mobile Menu */}
			<nav className="flex lg:hidden" id="menu">
				<button aria-label="Toggle Menu">
					<FontAwesomeIcon
						className="text-cycle-blue text-xl"
						icon={faBars}
					/>
				</button>
			</nav>
		</div>
	);
}
