import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export function NavLink({
	href,
	icon,
	title,
}: {
	href: string;
	icon: IconDefinition;
	title: string;
}) {
	return (
		<>
			{/* desktop nav link */}
			<li
				className={classNames(
					"hidden lg:block",
					"text-cycle-blue hover:text-cycle-blue-accent cursor-pointer font-semibold no-underline",
				)}
			>
				<a className="flex items-center gap-2" href={href}>
					<div className="w-6">
						<FontAwesomeIcon icon={icon} />
					</div>
					{title}
				</a>
			</li>

			{/* mobile nav link */}
			<li
				className={classNames(
					"block lg:hidden",
					"text-cycle-blue hover:text-cycle-blue-accent bg-cycle-black cursor-pointer rounded-md p-4",
					"border-cycle-blue-accent/80 border font-semibold",
				)}
			>
				<a className="flex items-center gap-2" href={href}>
					<div className="w-6">
						<FontAwesomeIcon icon={icon} />
					</div>
					{title}
				</a>
			</li>
		</>
	);
}
